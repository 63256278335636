import { Form } from '~/components/.shared/form/Form';

type Props = {
  errorLocation: 'top' | 'bottom';
};

export function ProductAreaSelect({ errorLocation }: Props) {
  return (
    <Form.SelectField label="Product area" name="productArea" errorLocation={errorLocation}>
      <option value="">Choose an option...</option>
      <option disabled value="recent-features">
        RECENT FEATURES
      </option>
      <option value="field-history">Field history</option>
      <option disabled value="product-areas">
        PRODUCT AREAS
      </option>
      <option value="account-dashboard">Account dashboard</option>
      <option value="content-hub">Content hub</option>
      <option value="content-editor">Content editor</option>
      <option value="integrations">Integrations</option>
      <option value="people-and-groups">People & Groups</option>
      <option value="projects">Projects</option>
      <option value="template-and-structure">Templates & structure</option>
      <option value="workflow">Workflow</option>
      <option value="other">Other</option>
    </Form.SelectField>
  );
}
