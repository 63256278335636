import { useSearchParams } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { shareFeedbackSchema } from '~/data/share-feedback/.schema/shareFeedbackSchema';

import { useHasIframeParam } from '~/helpers/hooks/useHasIframeParam';

import { Form } from '~/components/.shared/form/Form';

import { HeaderText } from './form/HeaderText';
import { ProductAreaSelect } from './form/ProductAreaSelect';

export const SHARE_FEEDBACK_FORM_ID = 'share-feedback-form';

export function ShareFeedbackForm() {
  const isIframe = useHasIframeParam();
  const [searchParams] = useSearchParams();
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <>
      {!isIframe && <h1 className="mb-6 text-3xl">Suggest a feature</h1>}
      {!isIframe && <HeaderText />}
      <Form
        id={SHARE_FEEDBACK_FORM_ID}
        schema={shareFeedbackSchema}
        method="post"
        action={`/share-feedback${isIframe ? '?iframe=true' : ''}`}
        className="w-full"
      >
        <ProductAreaSelect errorLocation={isIframe ? 'top' : 'bottom'} />
        <Form.RichTextField
          name="description"
          label="Feedback"
          errorLocation={isIframe ? 'top' : 'bottom'}
          wrapperClassName={isIframe ? '!mb-0' : ''}
        />
        <input type="hidden" name="originUrl" value={isIframe ? `${searchParams.get('origin')}` : currentUrl} />
        <input type="hidden" name="linkId" value={isIframe ? 'header-menu-button' : 'standalone-route'} />
        {!isIframe && <Form.SubmitButton className="w-full flex justify-center">Submit</Form.SubmitButton>}
      </Form>
    </>
  );
}
